import { StyleType } from "@iventis/domain-model/model/styleType";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import { PointStyle } from "@iventis/domain-model/model/pointStyle";
import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { ZoomableValue } from "@iventis/domain-model/model/zoomableValue";
import { ZoomableValueExtractionMethod } from "@iventis/domain-model/model/zoomableValueExtractionMethod";
import { LineModelStyle } from "@iventis/domain-model/model/lineModelStyle";
import { ModelStyle } from "@iventis/domain-model/model/modelStyle";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { LayerStyle } from "@iventis/domain-model/model/layerStyle";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { isValueEqualWithNullOrUndefinedCheck } from "@iventis/utilities";
import { TextStyle } from "@iventis/domain-model/model/textStyle";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { createStaticStyleValue, getStaticStyleValue } from "./static-styles";
import { defaultAreaStyle, defaultIconStyle, defaultLineModelStyle, defaultLineStyle, defaultModelStyle, defaultPointStyle } from "./default-style-values";
import { MapObjectProperties, BasicMapLayer, UnionOfStyles } from "../types/store-schema";
import { StylePropertyToValueMap } from "../types/internal";
import { getLayerStyle } from "./layer.helpers";
import { isModelLayer } from "./state-helpers";
import { ModelLayerStyle } from "../types/models";

export function getHighlightCircleRadius(currentRadius: number) {
    return currentRadius + 10;
}

/**
 * Modifies each occurance of a fundamental value within a style value, this includes datadriven and zoomable value instances
 * @param styleValue The style value to be modified
 * @param modifyFunc The function that modifies the fundamental value
 * @returns A new style value with new fundamental values
 * @example
 * modifyStyleValueFundamentalValues(layer.iconStyle.size, (value) => {
    // The base size of the highlight area for icons, gets scaled by the icon's scale
    const baseIconHighlightSizePx = 30;
    return baseIconHighlightSizePx * value;
});
 */
export function modifyStyleValueFundamentalValues<TFundamentalValue>(
    styleValue: StyleValue<TFundamentalValue>,
    modifyFunc: (fundamentalValue: TFundamentalValue) => TFundamentalValue
) {
    const newStyleValue: StyleValue<TFundamentalValue> = { ...styleValue };
    if (styleValue.extractionMethod === StyleValueExtractionMethod.Mapped) {
        Object.keys(styleValue.mappedValues).forEach((key) => {
            newStyleValue.mappedValues[key] = modifyZoomableValueFundamentalValues(styleValue.mappedValues[key], modifyFunc);
        });
    }
    newStyleValue.staticValue = modifyZoomableValueFundamentalValues(newStyleValue.staticValue, modifyFunc);
    return newStyleValue;
}

/**
 * Modifies each occurance of a fundamental value within a zoomable value
 * @param zoomableValue The zoomable value to be modified
 * @param modifyFunc The function that modifies the fundamental value
 * @returns A new zoomable value with new fundamental values
 */
export function modifyZoomableValueFundamentalValues<TFundamentalValue>(
    zoomableValue: ZoomableValue<TFundamentalValue>,
    modifyFunc: (fundamentalValue: TFundamentalValue) => TFundamentalValue
) {
    const newZoomValue: ZoomableValue<TFundamentalValue> = JSON.parse(JSON.stringify(zoomableValue));
    if (zoomableValue.extractionMethod !== ZoomableValueExtractionMethod.Static) {
        Object.keys(zoomableValue.mappedZoomValues).forEach((zoomLevel) => {
            newZoomValue.mappedZoomValues[zoomLevel].value = modifyFunc(newZoomValue.mappedZoomValues[zoomLevel].value);
        });
    }
    newZoomValue.staticValue = modifyFunc(newZoomValue.staticValue);
    return newZoomValue;
}

export type PointStyles = PointStyle | IconStyle | ModelStyle;

export type LineStyles = LineStyle | LineModelStyle;

// ❗  ALL STYLES UNION type is called UnionOfStyles, which exists in the map engine module

type StyleValueTypeFromStyleType<ST> = ST extends StyleType.Area
    ? AreaStyle
    : ST extends StyleType.Line
    ? LineStyle
    : ST extends StyleType.Point
    ? PointStyle
    : ST extends StyleType.Icon
    ? IconStyle
    : ST extends StyleType.Model
    ? IconStyle
    : ST extends StyleType.LineModel
    ? LineModelStyle
    : never;

export const getDefaultStyleProperty = <TStyleType extends StyleType, TStyleProperty extends keyof TStyle, TStyle = StyleValueTypeFromStyleType<TStyleType>>(
    styleType: TStyleType,
    property: TStyleProperty
): TStyle[typeof property] => {
    let returnValue: TStyle[typeof property];

    switch (styleType) {
        case StyleType.Area:
            returnValue = defaultAreaStyle[property as string];
            break;
        case StyleType.Line:
            returnValue = defaultLineStyle[property as string];
            break;
        case StyleType.Point:
            returnValue = defaultPointStyle[property as string];
            break;
        case StyleType.Icon:
            returnValue = defaultIconStyle[property as string];
            break;
        case StyleType.Model:
            returnValue = defaultModelStyle[property as string];
            break;
        case StyleType.LineModel:
            returnValue = defaultLineModelStyle[property as string];
            break;
        default:
            throw new Error("Style types was not matched");
    }
    if (returnValue == null) {
        throw new Error(`Property ${property.toLocaleString()} could not be found in style type ${styleType.toString()}`);
    }
    return returnValue;
};

/** Returns the default style for a given style type */
export const getDefaultStyle = (styleType: StyleType) => {
    switch (styleType) {
        case StyleType.Area:
            return defaultAreaStyle;
        case StyleType.Line:
            return defaultLineStyle;
        case StyleType.Point:
            return defaultPointStyle;
        case StyleType.Icon:
            return defaultIconStyle;
        case StyleType.Model:
            return defaultModelStyle;
        case StyleType.LineModel:
            return defaultLineModelStyle;
        default:
            throw new Error("Style types was not matched");
    }
};

/**
 * Omits any data driven styling on a style if that data field no longer exists
 */
export const stripStyleValuesWithRemovedDataFieldReferences = (layer: MapLayer, dataFieldId: string) => {
    const newLayer: MapLayer = { ...layer };
    Object.entries(newLayer).forEach(([styleKey, styleValue]) => {
        if (styleValue != null && typeof styleValue === "object" && "styleType" in styleValue) {
            Object.entries(styleValue).forEach(([key, value]) => {
                if (typeof value !== "object" || value == null) {
                    // Value is not a style value
                    return;
                }
                const styleValueObject = value as StyleValue<unknown>;
                if (dataFieldId === styleValueObject.dataFieldId) {
                    // Data field has been removed, just use the static value instead
                    newLayer[styleKey] = { ...styleValue, [key]: createStaticStyleValue(styleValueObject.staticValue.staticValue) };
                }
            });
        }
    });
    return newLayer;
};

export const stripStyleValuesWithRemovedListItemReference = (layer: MapLayer, listItemId: string) => {
    const newLayer = JSON.parse(JSON.stringify(layer));
    Object.values(newLayer).forEach((style) => {
        if (typeof style === "object" && style && "styleType" in style) {
            Object.values(style).forEach((styleValue: StyleValue<unknown>) => {
                Object.keys(styleValue?.mappedValues ?? {}).forEach((id) => {
                    if (id === listItemId) {
                        // eslint-disable-next-line no-param-reassign
                        delete styleValue.mappedValues[id];
                    }
                });
            });
        }
    });
    return newLayer;
};

export const styleTypeToLayerProperties = {
    [StyleType.Area]: "areaStyle",
    [StyleType.Line]: "lineStyle",
    [StyleType.Point]: "pointStyle",
    [StyleType.Icon]: "iconStyle",
    [StyleType.Model]: "modelStyle",
    [StyleType.LineModel]: "lineModelStyle",
} as const;

/**
 * Extracts the zoomable value from the given style value object and object property or list item id
 * @param style The style value object to extract the style value from
 * @param properties Either the properties of a map object OR a data field list item id
 * @returns The style value stored within the style value object
 */
export const extractZoomableValueFromStyleValue = <TFundamentalValue>(
    style: StyleValue<TFundamentalValue>,
    properties: MapObjectProperties | string
): ZoomableValue<TFundamentalValue> => {
    if (style.extractionMethod === StyleValueExtractionMethod.Mapped) {
        if (typeof properties === "string") {
            return style.mappedValues[properties] ?? style.staticValue;
        }
        return style.mappedValues[properties[style.dataFieldId] as string] ?? style.staticValue;
    }
    return style.staticValue;
};

/**
 * Extracts the fundamental value from a given zoomable value
 * @param zoomableValue - The zoomable value object to extract the style data from
 * @param zoomLevel - The current zoom level of the map
 * @returns The style value stored within the zoomable value
 */
export const extractValueFromZoomableValue = <TFundamentalValue, TZoomableValue extends ZoomableValue<TFundamentalValue>>(
    zoomableValue: TZoomableValue,
    zoomLevel: number
): TFundamentalValue => {
    switch (zoomableValue.extractionMethod) {
        case ZoomableValueExtractionMethod.Closest: {
            // Return the value at the key that is closest to zoomLevel

            // Storets the value that is closest to the given zoomLevel
            let closestValue = 0;

            // Stores the difference between the given zoomLevel and the current closest value
            let smallestDif = Infinity;

            // Iterate through each key and compare the difference against the given zoomLevel
            Object.keys(zoomableValue.mappedZoomValues).forEach((key) => {
                const typedKey = (key as unknown) as number;
                const diff = Math.abs(zoomLevel - typedKey);
                if (diff < smallestDif) {
                    closestValue = typedKey;
                    smallestDif = diff;
                }
            });

            // Return the value at the closest zoom level key
            const zoomValue = zoomableValue.mappedZoomValues[closestValue];
            return zoomValue.value;
        }
        case ZoomableValueExtractionMethod.Static: {
            return zoomableValue.staticValue;
        }
        default: {
            // eslint-disable-next-line no-console
            console.error(`Extraction method ${zoomableValue.extractionMethod} is not yet supported, returning static value`);
            return zoomableValue.staticValue;
        }
    }
};

/**
 * Extracts the fundamental value from the given style value object
 * @param style - the style value object to extract the style from
 * @param properties - either the properties of a map object OR a data field list item id
 * @param zoomLevel - the zoom level of the map currently
 * @returns the fundamental value
 */
export const extractStyleValue = <TFundamentalValue>(style: StyleValue<TFundamentalValue>, properties: MapObjectProperties | string, zoomLevel: number): TFundamentalValue =>
    extractValueFromZoomableValue(extractZoomableValueFromStyleValue(style, properties), zoomLevel);

export function findDifferingStyleProperties<T extends LayerStyle = LayerStyle>(newStateStyle: T, previousStateStyle: T): StylePropertyToValueMap[] {
    const changedStyleValues = Object.keys(newStateStyle).filter((styleProperty) =>
        styleProperty === "styleType"
            ? previousStateStyle[styleProperty] !== newStateStyle[styleProperty]
            : hasStyleValueChanged(previousStateStyle[styleProperty], newStateStyle[styleProperty])
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return changedStyleValues.map((styleProperty) => ({ styleProperty: styleProperty as any, value: newStateStyle[styleProperty] }));
}

/** If both are null or undefined, return false. If one is null (or undefined) and one isn't, return true  */
const nullOrUndefinedDiffCheck = (val1: unknown, val2: unknown) => {
    if (val1 == null || val2 == null) {
        return (val1 == null && val2 != null) || (val1 != null && val2 == null);
    }
    return false;
};

export const hasStyleValueChanged = (oldStyle: StyleValue<unknown>, newStyle: StyleValue<unknown>) => {
    if (oldStyle == null || newStyle == null) {
        return nullOrUndefinedDiffCheck(oldStyle, newStyle);
    }
    // Check StyleValue
    let changed = oldStyle.extractionMethod !== newStyle.extractionMethod || !isValueEqualWithNullOrUndefinedCheck(oldStyle.dataFieldId, newStyle.dataFieldId);

    // Check ZoomableValue - we cannot check object to object as when creating a data driven style we do *technically* create a new static value even if the values are the same
    changed = changed || hasZoomableValueChanged(oldStyle.staticValue, newStyle.staticValue);

    // Check StyleValue mapped values
    let newStyleMappedValueKeys = Object.keys(newStyle.mappedValues || {});
    Object.entries(oldStyle.mappedValues || {}).forEach(([key, value]) => {
        const newVal = newStyle.mappedValues?.[key];
        changed = changed || nullOrUndefinedDiffCheck(newVal, value) || hasZoomableValueChanged(value, newVal);

        // Only store mismatching keys
        newStyleMappedValueKeys = newStyleMappedValueKeys.filter((newKey) => newKey !== key);
    });
    // If a new key has been added to the mapping, we must catch that here
    changed = changed || newStyleMappedValueKeys.length > 0;

    return changed;
};

export const hasZoomableValueChanged = (oldValue: ZoomableValue<unknown>, newValue: ZoomableValue<unknown>): boolean => {
    // Check if extraction method or static value has changed
    let changed = oldValue.extractionMethod !== newValue.extractionMethod || oldValue.staticValue !== newValue.staticValue;

    // Check if mapped zoom values have changed
    let newValueKeys = Object.keys(newValue.mappedZoomValues || {});
    Object.entries(oldValue.mappedZoomValues || {}).forEach(([key, value]) => {
        const newVal = newValue.mappedZoomValues?.[key];
        changed = changed || nullOrUndefinedDiffCheck(newVal, value) || value !== newVal;

        // Our old key against all our new keys as this means a change in zoom level
        newValueKeys = newValueKeys.filter((newKey) => newKey !== key);
    });

    // Check if we have any changes in keys
    changed = changed || newValueKeys.length > 0;

    return changed;
};

/**
 * Returns an style prop/value map of the styles that use or depend on the provided datafield ids
 */
export function extractStyleMappingsByDataFieldIds<T extends LayerStyle = LayerStyle>(datafieldIds: string[], style: T): StylePropertyToValueMap[] {
    if (!datafieldIds || datafieldIds.length === 0) {
        return [];
    }
    const changedStyleValues = Object.keys(style).filter((styleProperty) => style[styleProperty]?.dataFieldId && datafieldIds.includes(style[styleProperty].dataFieldId));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return changedStyleValues.map((styleProperty) => ({ styleProperty: styleProperty as any, value: style[styleProperty] }));
}

export function getTextStyle(layer: BasicMapLayer): TextStyle {
    const style = getLayerStyle(layer);

    if (style.styleType === StyleType.LineModel || style.styleType === StyleType.Model) {
        throw new Error(`Style type ${style.styleType} does not contain text`);
    }

    return {
        styleType: style.styleType,
        text: style.text,
        textContent: style.textContent,
        textColour: style.textColour,
        textSize: style.textSize,
        textOverlap: style.textOverlap,
        textBold: style.textBold,
        textItalic: style.textItalic,
        textUnderlined: style.textUnderlined,
        textOutlineWidth: style.textOutlineWidth,
        textOutlineColour: style.textOutlineColour,
        textOpacity: style.textOpacity,
        textPosition: style.textPosition,
        textOffset: style.textOffset,
        objectOrder: undefined,
    };
}

/** Check if a layer's style is valid */
export const isLayerStyleValid = (layer: MapLayer) => {
    if (layer.styleType == null) {
        return false;
    }
    return isStyleValid(getLayerStyle(layer));
};

/** Checks if the style is valid */
export const isStyleValid = (layerStyle: UnionOfStyles) => {
    if (layerStyle == null) {
        return false;
    }

    if (Object.keys(layerStyle).length === 0) {
        return false;
    }

    if (Object.values(layerStyle).some((styleProperty) => styleProperty == null)) {
        return false;
    }

    return true;
};

/** Returns mapped and static values as an array */
export const getStaticAndMappedValues = <TStyleValue>(value: StyleValue<TStyleValue>): TStyleValue[] => {
    switch (value.extractionMethod) {
        case StyleValueExtractionMethod.Static:
            return [getStaticStyleValue(value)];
        case StyleValueExtractionMethod.Mapped:
            return [...Object.values<ZoomableValue<TStyleValue>>(value.mappedValues).flatMap(({ staticValue }) => staticValue), value.staticValue.staticValue];
        default:
            throw new Error(`${value.extractionMethod} is not supported`);
    }
};

/** Gets the most prominent style value by layer type (i.e. for an icon it would be "iconImage") */
export const getMostProminentStyleValue = (layer: MapLayer) => {
    const layerStyle = layer[styleTypeToLayerProperties[layer.styleType]];
    return isModelLayer(layer)
        ? (layerStyle as ModelLayerStyle).model
        : layer.styleType === StyleType.Icon
        ? (layerStyle as IconStyle).iconImage
        : (layerStyle as AreaStyle | LineStyle | PointStyle).colour;
};
