import { createAssetUrlGetter, localApiPortNumbers, initialiseAxios, MicroService, applyApiKeyHeaders } from "@iventis/api-helpers";
import { API_KEY_QUERY_PARAM_KEY, queryParams } from "@iventis/utilities";
import { IventisEnvironment } from "./iventis-environment.types";

// Which API to use from local frontend to local backed
const useLocalApi = false;

// Which API to use from local frontend to remote backend
const remoteApi = "dev.iventis.studio/api/";

// Which WS to use from local frontend to remote backend
const remoteWs = "dev.iventis.studio/ws/";

// Get the environment data from the cookie if present (only when deployed)
const iventisEnvironmentFromCookie = document.cookie
    .split(";")
    ?.find((cookie) => cookie.includes("IVENTIS-ENVIRONMENT="))
    ?.split("=")[1];
let iventisEnvironment: IventisEnvironment;
if (iventisEnvironmentFromCookie) {
    iventisEnvironment = JSON.parse(decodeURIComponent(iventisEnvironmentFromCookie));
} else {
    // We are running locally, so we need to set the API URLs manually
    const hostname = window.location.hostname.toLowerCase();

    const runningInLocalhost = hostname.toLowerCase().endsWith("localhost");

    if (!runningInLocalhost) {
        if (hostname.toLowerCase().endsWith("iventis.sa.com")) {
            // If we're in the saudi environment, we need to set the API URLs manually
            const hostnameSplit = hostname.split(".");
            const inclSubDomain = hostnameSplit.length > 4;
            const subDomain = inclSubDomain ? hostnameSplit[0] : null;
            const appUrl = (inclSubDomain ? hostnameSplit.slice(1) : hostnameSplit).join(".");
            iventisEnvironment = {
                apiProtocol: "https://",
                appProtocol: "https://",
                wsProtocol: "wss://",
                apiUrl: `${appUrl}/api/`,
                wsUrl: `${appUrl}/ws/`,
                appUrl,
                subDomain,
                signUpUrl: "https://www.iventis.com/free-trial/",
                enableFrontendObservabilityAndMonitoring: !["dev", "staging"].some((env) => appUrl.includes(env)),
            };
        } else {
            throw new Error("Iventis environment data not received from server and application is not running in localhost. This configuration is not supported.");
        }
    } else {
        const subDomain = hostname.endsWith(".localhost") ? hostname.split(".")[0] : null;

        iventisEnvironment = {
            apiProtocol: "https://",
            appProtocol: "http://",
            wsProtocol: "wss://",
            apiUrl: remoteApi,
            wsUrl: remoteWs,
            appUrl: "localhost:8000",
            subDomain,
            signUpUrl: "https://www.iventis.com/free-trial/",
            enableFrontendObservabilityAndMonitoring: false,
        };
    }
}

// Remove trailing slash from API URLs
iventisEnvironment.apiUrl = iventisEnvironment.apiUrl.endsWith("/") ? iventisEnvironment.apiUrl.slice(0, -1) : iventisEnvironment.apiUrl;

export const getApiBaseUrl = (microservice: MicroService) => {
    if (useLocalApi) {
        return `http://${iventisEnvironment.subDomain != null ? `${iventisEnvironment.subDomain}.` : ""}localhost:${localApiPortNumbers[microservice]}/`;
    }

    if (iventisEnvironment.subDomain != null) {
        return `${iventisEnvironment.apiProtocol}${iventisEnvironment.subDomain}.${iventisEnvironment.apiUrl}/${microservice}/`;
    }

    return `${iventisEnvironment.apiProtocol}${iventisEnvironment.apiUrl}/${microservice}/`;
};

export const getApiBaseUrlSpeficicSubdomain = (microservice: MicroService, subDomain: string) => {
    if (useLocalApi) {
        return `http://${subDomain != null ? `${subDomain}.` : ""}localhost:${localApiPortNumbers[microservice]}/`;
    }

    if (subDomain != null) {
        return `${iventisEnvironment.apiProtocol}${subDomain}.${iventisEnvironment.apiUrl}/${microservice}/`;
    }

    return `${iventisEnvironment.apiProtocol}${iventisEnvironment.apiUrl}/${microservice}/`;
};

export const getWsBaseUrl = () => {
    const apiKey = queryParams[API_KEY_QUERY_PARAM_KEY];
    let url = "";
    if (useLocalApi) {
        url = "ws://localhost:5100/ws/";
    } else if (iventisEnvironment.subDomain != null) {
        url = `${iventisEnvironment.wsProtocol}${iventisEnvironment.subDomain}.${iventisEnvironment.wsUrl}`;
    } else {
        url = `${iventisEnvironment.wsProtocol}${iventisEnvironment.wsUrl}`;
    }

    if (apiKey) {
        url = `${url}?${API_KEY_QUERY_PARAM_KEY}=${apiKey}`;
    }

    return url;
};

export const getAppUrlForSubdomain = (subdomain: string) => `${iventisEnvironment.appProtocol}${subdomain}.${iventisEnvironment.appUrl}`;

export const getIventisEnvironmentData = () => iventisEnvironment;

// We know if the application is deployed because the cookie was present
export const isDeployed = iventisEnvironmentFromCookie != null;

// Optionally Apply api key headers. This is needed when authenticating with API Keys. An example use case: External user using shared links.
export const { apis, assetCacheService } = initialiseAxios(getApiBaseUrl, { withCredentials: true, headers: applyApiKeyHeaders(false) });

export const permissionsApi = apis[MicroService.PERMISSIONS];
export const libraryApi = apis[MicroService.LIBRARY];
export const mappingApi = apis[MicroService.MAPPING];
export const assetsApi = apis[MicroService.ASSETS];
export const scheduleApi = apis[MicroService.SCHEDULE];
export const exportsApi = apis[MicroService.EXPORTS];
export const commentsApi = apis[MicroService.COMMENTS];
export const notificationsApi = apis[MicroService.NOTIFICATIONS];
export const { cdnAssets, iventisApi } = apis;

export const { multipleAssetUrlGetter, assetUrlGetter, bustCacheIds } = createAssetUrlGetter(assetsApi, assetCacheService);
