export enum IsoCurrencyCode {
    PEN = "PEN",
    PHP = "PHP",
    PLN = "PLN",
    EUR = "EUR",
    USD = "USD",
    QAR = "QAR",
    RON = "RON",
    RUB = "RUB",
    SAR = "SAR",
    RSD = "RSD",
    SGD = "SGD",
    ZAR = "ZAR",
    SEK = "SEK",
    CHF = "CHF",
    THB = "THB",
    TTD = "TTD",
    TRY = "TRY",
    UAH = "UAH",
    AED = "AED",
    GBP = "GBP",
    UYU = "UYU",
    UZS = "UZS",
    VND = "VND",
    YER = "YER",
    INR = "INR",
    AUD = "AUD",
    CAD = "CAD",
    NZD = "NZD"
}