import { v4 as uuid } from "uuid";
import {
    MapState,
    MapModuleLayer,
    LayerStorageScope,
    LayerDrawingControl,
    LayerDrawingControls,
    LocalGeoJson,
    LocalGeoJsonObject,
    SelectedMapObject,
    Source,
} from "@iventis/map-engine/src/types/store-schema";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { Map } from "@iventis/domain-model/model/map";
import { MapMode } from "@iventis/map-engine/src/machines/map-machines.types";
import { LayerStyle } from "@iventis/domain-model/model/layerStyle";
import { COMMENT_VECTOR_SOURCE_ID } from "@iventis/map-engine/src/bridge/constants";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { MapObject } from "@iventis/domain-model/model/mapObject";
import { getLayerStyle, setLayerStyle } from "@iventis/map-engine/src/utilities/layer.helpers";
import { isAnalysisLayer, isModelLayer } from "@iventis/map-engine/src/utilities/state-helpers";
import { PagedResult } from "@iventis/domain-model/model/pagedResult";
import { getAssetsByIds } from "@iventis/api-helpers";
import { DomainLayer, MapGlobalState } from "@iventis/map-engine/src/state/map.state.types";
import { ViewByStatus } from "@iventis/map-engine/src/types/layer-view-by";
import { parseISO } from "date-fns";
import { MapObjectAggregated } from "@iventis/domain-model/model/mapObjectAggregated";
import { Asset } from "@iventis/domain-model/model/asset";
import { Style } from "mapbox-gl";
import { googleMapBackgroundAssetTag } from "@iventis/map-engine/src/bridge/mapbox/engine-mapbox-types-and-constants";
import { IventisRequestConfig } from "@iventis/api-helpers/src/api.types";
import { assetsApi, mappingApi } from "@iventis/api/src/api";
import { getAllMapObjectRepeatedTimeRangeValues, isMapObjectWithinSingleRepeatedTimeRange } from "@iventis/map-engine";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { checkModeIsPresent } from "@iventis/map-engine/src/machines/mode-machine.helpers";
import { getAssetSignature } from "@iventis/utilities";
import { baseURL } from "./map.slice";

/* eslint-disable no-param-reassign */
export function stampValue<ValueType>(stateEntry: { stamp: string; value: ValueType }, value: ValueType) {
    stateEntry.stamp = uuid();
    stateEntry.value = value;
}

export function setMode(state: MapState, mode: MapMode) {
    state.mode.value = mode;
    state.mode.stamp = uuid();
}

export function createNewLocalId(state: MapState) {
    const id = uuid();
    stampValue(state.localToRemoteMapObjectIdsMap, {
        ...state.localToRemoteMapObjectIdsMap.value,
        [id]: undefined,
    });
    return id;
}

export function setIdPair(state: MapState, localId: string, remoteId: string) {
    if (localId === undefined) {
        throw new Error("At least a local ID must be provided");
    }
    stampValue(state.localToRemoteMapObjectIdsMap, {
        ...state.localToRemoteMapObjectIdsMap.value,
        [localId]: remoteId,
    });
}

export function setLocalToRemoteMapObjectIds(state: MapState, pairs: { key: string; value: string }[]) {
    stampValue(state.localToRemoteMapObjectIdsMap, {
        ...state.localToRemoteMapObjectIdsMap.value,
        ...pairs.reduce(
            (cum, { key, value }) => ({
                ...cum,
                [key]: value,
            }),
            {} as { [localId: string]: string }
        ),
    });
}

export function composeNewObjects(state: MapState, objectIds: string[]) {
    stampValue(state.localToRemoteMapObjectIdsMap, {
        ...state.localToRemoteMapObjectIdsMap.value,
        ...objectIds.reduce((cum, objectId) => ({ ...cum, [objectId]: undefined }), {}),
    });
}

/** Takes the new object selection, and updates the selected property of the affected analysis objects */
export function updateAnalysisObjectsSelectionProperty(state: MapState, newSelection: SelectedMapObject[]) {
    state.mapObjectsSelected.value.forEach((prev) => {
        if (isAnalysisLayer(prev.layerId) && !newSelection.some((next) => prev.objectId === next.objectId)) {
            const currentFeature = state.geoJSON.value[prev.layerId]?.find?.((object) => object.objectId === prev.objectId)?.feature;
            if (currentFeature != null) {
                currentFeature.properties.selected = undefined;
            }
        }
    });
    newSelection.forEach((next) => {
        if (isAnalysisLayer(next.layerId) && !state.mapObjectsSelected.value.some((prev) => prev.objectId === next.objectId)) {
            const currentFeature = state.geoJSON.value[next.layerId]?.find?.((object) => object.objectId === next.objectId)?.feature;
            if (currentFeature != null) {
                currentFeature.properties.selected = "selected";
            }
        }
    });
    stampValue(state.geoJSON, state.geoJSON.value);
}

export function updateLayerStyle(state: MapGlobalState, layerId: string, styledLayer: MapLayer) {
    const style: LayerStyle = getLayerStyle(styledLayer);
    let layer = state.mapModule.layers.value.find((layer) => layer.id === layerId);
    if (layer == null) {
        throw new Error(`Layer with id of ${layerId} can not be found`);
    }
    layer = setLayerStyle(layer, style);
    updateMapLayerWithStamp(state, layer);
}

export function updateMapLayerWithStamp(state: MapGlobalState, layer: MapModuleLayer) {
    const newLayers: MapModuleLayer[] = [...state.mapModule.layers.value.filter((value) => value.id !== layer.id), { ...layer, stamp: uuid() }];
    stampValue(state.mapModule.layers, newLayers);
}

export function updateLayerSelectedValue(state: MapGlobalState, selectedLayerIds: string[]) {
    const stateLayersCopy = [...state.mapModule.layers.value];
    let changed = false;
    stateLayersCopy.forEach((layer) => {
        const selected = selectedLayerIds.includes(layer.id);
        if (selected !== layer.selected) {
            layer.selected = selectedLayerIds.includes(layer.id);
            changed = true;
        }
    });
    return { layers: stateLayersCopy, changed } as const;
}

// Sets the basic requirements of the map to be able to render the map-root component
export function setMap(state: MapGlobalState, payload: Map) {
    state.mapModule.mapId = payload.id;
    state.mapName = payload.name;
    state.savedMapViews = payload.savedMapViews;
    state.savedMapViewDefault = payload.defaultSavedMapViewId;
    const defaultsavedMapView = payload.savedMapViews && payload.defaultSavedMapViewId ? payload.savedMapViews.find((mv) => mv.id === payload.defaultSavedMapViewId) : null;
    if (defaultsavedMapView) {
        const { longitude, latitude, zoom, bearing, pitch } = defaultsavedMapView.position;
        stampValue(state.mapModule.position, { lng: longitude, lat: latitude, zoom, bearing, pitch, source: Source.HOST }); // Source Init because engine constructor will use this value
        state.selectedBasemap.level = defaultsavedMapView.level;
        state.mapModule.currentLevel = defaultsavedMapView.level;
    }
    if (payload.layers != null) {
        const parsedLayers = payload.layers.map((l) => convertLayerDate(l));
        const layers = [...state.mapModule.layers.value, ...parsedLayers.map((lyr) => parseLayerToIventisLayer(lyr))];
        stampValue(state.mapModule.layers, layers || []);
    }
    stampValue(state.mapModule.tileSources, {
        objects: {
            ...state.mapModule.tileSources.value.objects,
            tiles: [
                {
                    name: "iventis",
                    tiles: [`${baseURL}map/${payload.id}/tiles/{z}/{x}/{y}.pbf?ignoreZoomOptimisation=false`],
                    type: "vector",
                },
            ],
        },
        comments: {
            ...state.mapModule.tileSources.value.comments,
            tiles: [
                {
                    name: COMMENT_VECTOR_SOURCE_ID,
                    tiles: [`${baseURL}maps/${payload.id}/comments/tiles/{z}/{x}/{y}.pbf?ignoreZoomOptimisation=false`],
                    type: "vector",
                },
            ],
        },
    });
    state.selectedBasemap.MapBackground = payload.backgroundId;
    state.savedBackgroundDefault = payload.backgroundId;
    stampValue(state.mapModule.terrain3D, { enabled: payload.terrainEnabled, exaggeration: state.mapModule.terrain3D.value.exaggeration });
    stampValue(state.mapModule.globe, payload.globeEnabled);
    state.sitemapConfigs = payload.sitemapConfigs;
    stampValue(state.mapModule.buildings3D, payload.buildings3DEnabled);
}

export const getLayerThumbnailAssets = async (layers: MapLayer[], maxRetries = 0, retries = 0): Promise<{ assetUrl: string; layerId: string }[]> => {
    const layerIdsAssetIds = layers.map((layer) => ({ layerId: layer.id, assetId: layer.id }));
    // Want the unique asset ids
    const assetIds = [...new Set(layerIdsAssetIds.map(({ assetId }) => assetId))];
    const data = await getAssetsByIds(assetIds, assetsApi);
    // If any have failed and we want to retry then do so (reason for failure could be a 404 if the asset hasn't been created yet)
    if (data.failed?.length > 0 && retries < maxRetries) {
        await new Promise((resolve) => setTimeout(resolve, 4000));
        const result = await getLayerThumbnailAssets(layers, maxRetries, retries + 1);
        return result;
    }
    const found = data.found.filter((r) => r != null); // Remove filter after BUG: 11019
    const urlsWithLayerIds = layerIdsAssetIds.reduce((cum, { layerId, assetId }) => {
        const asset = found.find((asset) => asset.id === assetId);
        if (asset == null) {
            return cum;
        }
        const { assetUrl, authoritySignature } = asset;
        cum.push({
            assetUrl: getAssetSignature(assetUrl, authoritySignature),
            layerId,
        });
        return cum;
    }, []);
    return urlsWithLayerIds;
};

export const getMapLayers = async (mapId: string) => {
    const { data } = await mappingApi.get<MapLayer[]>(`/maps/${mapId}/layers`);
    return data;
};

// Sets the map view is the active one
export function setMapView(state: MapGlobalState, payload: string) {
    const mapView = state.savedMapViews.find((mv) => mv.id === payload);
    if (mapView) {
        stampValue(state.mapModule.position, {
            lng: mapView.position.longitude,
            lat: mapView.position.latitude,
            zoom: mapView.position.zoom,
            bearing: mapView.position.bearing,
            pitch: mapView.position.pitch,
            source: Source.HOST,
        });
        state.mapModule.currentLevel = mapView.level;
        state.selectedBasemap.level = mapView.level;
    }
}

export function set3DTerrian(state: MapGlobalState, payload: boolean) {
    stampValue(state.mapModule.terrain3D, { enabled: payload, exaggeration: 2 });
}

export function setGlobe(state: MapGlobalState, payload: boolean) {
    stampValue(state.mapModule.globe, payload);
}

/**
 * Assumes any inputted layer is remote
 * @param layer
 * @returns
 */
export function parseLayerToIventisLayer(layer: MapLayer & Partial<DomainLayer>, remote = true): DomainLayer {
    return {
        ...layer,
        storageScope: isModelLayer(layer) ? LayerStorageScope.LocalOnly : LayerStorageScope.LocalAndTiles,
        source: isModelLayer(layer) ? layer.id : "iventis",
        stamp: "",
        selected: layer.selected,
        viewBy: layer.viewBy ?? ViewByStatus.OBJECT,
        remote,
        drawingControls: getDrawingControlsForStyleType(layer.styleType),
    };
}

export function getDrawingControlsForStyleType(styleType: StyleType): LayerDrawingControls {
    switch (styleType) {
        case StyleType.Line:
        case StyleType.LineModel:
        case StyleType.Area:
            return {
                [LayerDrawingControl.ROTATION_HANDLE]: true,
                [LayerDrawingControl.MID_POINT_HANDLE]: true,
                [LayerDrawingControl.COORDINATE_HANDLE]: true,
            };
        case StyleType.Model:
            return {
                [LayerDrawingControl.ROTATION_HANDLE]: true,
                [LayerDrawingControl.MID_POINT_HANDLE]: false,
                [LayerDrawingControl.COORDINATE_HANDLE]: false,
            };
        default:
            return {
                [LayerDrawingControl.ROTATION_HANDLE]: false,
                [LayerDrawingControl.MID_POINT_HANDLE]: false,
                [LayerDrawingControl.COORDINATE_HANDLE]: false,
            };
    }
}

export function removeObjects(state: MapGlobalState, objectIds: string[]) {
    // Update object selection to not include the deleted objects
    const newMapObjectsSelected = state.mapModule.mapObjectsSelected.value.filter((object) => !objectIds.some((deletedId) => deletedId === object.objectId));
    stampValue(state.mapModule.mapObjectsSelected, newMapObjectsSelected);
    // Remove local object geometries for the removed object IDs
    const newLocalObjects = Object.entries(state.mapModule.geoJSON.value).reduce(
        (cum, [layerId, objects]) => ({
            ...cum,
            // For this particular layer, remove all the object geometries that have IDs matching our removed object IDs
            [layerId]: objects.filter((object) => !objectIds.some((deletedObjectId) => object.objectId === deletedObjectId)),
        }),
        {} as LocalGeoJson
    );
    stampValue(state.mapModule.geoJSON, newLocalObjects);
}

/** If the layer si to be shown in the sidebar, this function will return true */
export const isSidebarLayer = (layer: MapModuleLayer) => layer != null && !isAnalysisLayer(layer.id);

/** Gets objects that will always be local only and not possible through tiles (models) */
export const getRemoteObjects = async (filter: IventisRequestConfig, signal?: AbortSignal) =>
    mappingApi.get<PagedResult<MapObjectAggregated[]>>(`/maps/${filter.params.mapId}/map_objects/filter`, { ...filter, signal });

// Extracts dataFieldValues (which is either a string or DataFieldListItem) and adds them to the local GeoJsonObject's properties
export const extractDataFieldValues = (dataFieldValues: MapObject["dataFieldValues"], object: LocalGeoJsonObject) => {
    Object.entries(dataFieldValues).forEach(([dataFieldId, dataFieldValue]) => {
        let value = dataFieldValue;
        if (value == null) {
            delete object.feature.properties[dataFieldId];
            return;
        }
        const isObject = typeof dataFieldValue === "object";
        const isArray = Array.isArray(dataFieldValue);
        if (isObject && !isArray && dataFieldValue != null) {
            value = (dataFieldValue as DataFieldListItem).id;
        }

        object.feature.properties = {
            ...object.feature.properties,
            [dataFieldId]: value,
        };
    });
};

export const patchLocalMapObjects = (mapObjects: Partial<MapObject>[], geoJSON: LocalGeoJson) => {
    Object.entries(geoJSON).forEach(([, existingMapObjects]) => {
        existingMapObjects.forEach((existingMapObject) => {
            const mapObjectUpdate = mapObjects.find((updatedMapObject) => updatedMapObject.id === existingMapObject.objectId);
            if (mapObjectUpdate !== undefined) {
                existingMapObject.feature.properties = { ...existingMapObject.feature.properties };
                extractDataFieldValues(mapObjectUpdate.dataFieldValues, existingMapObject);
            }
        });
    });
    return geoJSON;
};

/**
 * Converts layer dates from the backend for the given layer to the correct javascript object
 * Return the corrected layer
 */
export const convertLayerDate = (layer: MapLayer): MapLayer => {
    const newLayer = layer;
    newLayer.createdAt = layer.createdAt != null ? parseISO(layer.createdAt.toString()) : undefined;
    newLayer.lastUpdatedAt = layer.lastUpdatedAt != null ? parseISO(layer.lastUpdatedAt.toString()) : undefined;
    newLayer.lastViewedAt = layer.lastViewedAt != null ? parseISO(layer.lastViewedAt.toString()) : undefined;
    return newLayer;
};

/** Gets metaData and tags from the map background asset and adds it to the style  */
export function addMetaDataAndTagsToMapBackgroundStyle(assets: Asset[], mapBackgroundId: string, mapStyles: { MapBackground: Style }) {
    const mapBackgroundAsset = assets.find((background) => background.id === mapBackgroundId);
    // Only update the metadata if tagged as "Google map"and the meta data hasn't been set
    if (mapBackgroundAsset.tags.includes(googleMapBackgroundAssetTag) && !mapStyles.MapBackground.metadata?.tags?.includes(googleMapBackgroundAssetTag)) {
        mapStyles.MapBackground.metadata = { ...mapBackgroundAsset?.metaData, ...mapStyles.MapBackground.metadata, tags: mapBackgroundAsset?.tags };
        return mapStyles;
    }
    return null;
}

/** Checks if a layer has image attribute and if it has update the map objects */
export const patchImageAttributes = (updatedMapObjects: MapObject[], geoJSON: LocalGeoJson, layers: DomainLayer[]) => {
    // Flag for if any changes have been made
    let changes = false;
    layers.forEach((layer) => {
        // Check if the layer has an datafield image attributes
        const hasLayerHaveImageAttribute = layer.dataFields.some((df) => df.type === DataFieldType.Image);
        // Check if that layer has any map object updates
        const hasLayerGotAUpdatedMapObject = updatedMapObjects.some((mapObject) => mapObject.layerId === layer.id);
        if (hasLayerHaveImageAttribute && hasLayerGotAUpdatedMapObject) {
            // Cycle through the map object updates and update their attribute values
            updatedMapObjects.forEach((updatedMapObject) => {
                const mapObject = geoJSON[updatedMapObject.layerId].find((mapObject) => updatedMapObject.id === mapObject.objectId);
                if (mapObject != null) {
                    changes = true;
                    mapObject.feature.properties = { ...mapObject.feature.properties };
                    extractDataFieldValues(updatedMapObject.dataFieldValues, mapObject);
                }
            });
        }
    });
    return changes ? geoJSON : null;
};

/** Given an update date filter, will update all the relevant state for the map */
export function updateMapStateWithFilterChange(state: MapGlobalState, updatedFilter?: MapGlobalState["mapModule"]["datesFilter"]["value"]) {
    if (updatedFilter == null) {
        updatedFilter = state.mapModule.datesFilter.value;
    }

    const { datesFilter, geoJSON, layers, mapObjectsSelected, mode } = state.mapModule;
    // If in composition mode we don't want to filter the object currently being drawn, when we finish drawing we will filter them
    const isCompositionMode = checkModeIsPresent(mode.value, "composition");
    const mapObjectIdsNotToFilter = isCompositionMode ? mapObjectsSelected.value.map((mo) => mo.objectId) : [];
    // Set which map objects should be filtered
    const filteredMapObjectIds = setLocalMapObjectsDateFilterValue(geoJSON.value, layers.value, updatedFilter.day, updatedFilter.time, mapObjectIdsNotToFilter);

    // Stamp all values
    stampValue(datesFilter, updatedFilter);
    stampValue(geoJSON, geoJSON.value);
    if (!isCompositionMode) {
        stampValue(
            mapObjectsSelected,
            mapObjectsSelected.value.filter((mo) => !filteredMapObjectIds.includes(mo.objectId))
        );
    }
}

/**
 * Sets a dateFiltered property on the map objects based on the repeated time ranges data fields
 */
export function setLocalMapObjectsDateFilterValue(localGeojson: LocalGeoJson, layers: MapModuleLayer[], dayFilter: number, timeFilter: number, mapObjectIdsNotToFilter: string[]) {
    const filteredMapObjectIds = [];
    layers.forEach((layer) => {
        // Ensure we are only checking user layers and not system ones such as analysis or area select
        if (layer.remote) {
            // Only check the layers that have repeated time ranges data fields
            const repeatedTimeRangesDataFields = layer.dataFields.filter((df) => df.type === DataFieldType.RepeatedTimeRanges);
            if (repeatedTimeRangesDataFields.length > 0) {
                const mapObjects = localGeojson[layer.id];
                if (mapObjects != null) {
                    mapObjects.forEach(({ feature }) => {
                        if (!mapObjectIdsNotToFilter.includes(feature.properties.id)) {
                            // Get all of the repeated time range values for the map object
                            const dateFilterValues = getAllMapObjectRepeatedTimeRangeValues(feature, repeatedTimeRangesDataFields);
                            const isFiltered = dateFilterValues.length === 0 ? false : !isMapObjectWithinSingleRepeatedTimeRange(dateFilterValues, dayFilter, timeFilter);
                            // Set the dateFiltered property
                            feature.properties.dateFiltered = isFiltered;
                            if (isFiltered) {
                                filteredMapObjectIds.push(feature.properties.id);
                            }
                        }
                    });
                }
            }
        }
    });
    return filteredMapObjectIds;
}
