import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledVerticalRule } from "@iventis/styles/src/components/vertical-rule";
import { decimalAdjust } from "@iventis/utilities";
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { styled, media } from "@iventis/styles";
import { borderRadius, fontSizes, gapWithRoomForErrorMessage, inputHeight } from "@iventis/styles/src/atomic-rules";
import { Theme } from "@emotion/react";

import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { dataTestIds } from "@iventis/testing";
import { SelectItemsComponent } from "./select-items";
import { ValueSelector, ValueSelectorComponentCreator } from "./component.types";
import { DisabledOverlay } from "../disabled-overlay";

export interface IncrementalValueProps extends ValueSelector<number> {
    minValue: number;
    maxValue: number;
    increment: number;
    units?: { id: string; name: string }[];
    selectedUnitId?: string;
    showUnit?: boolean;
    decimals?: number;
    fullWidth?: boolean;
    testId?: string;
    hideError?: boolean;
    alwaysEmitValue?: boolean;
    dataTestId?: string;
}

enum StepChange {
    INCREASE = "INCREASE",
    DECREASE = "DECREASE",
}

/**
 * Component where the value can be increase by increments or typed in by the user
 * @param { number } inputValue - the value used initially
 * @param { number } selectedUnitId - the id of the selected unit
 * @param { { id: string; name: string }[] } units - an array of ids and names for the different units which can be selected
 * @param { number } minValue - the minimum value the component can be if a value is chosen which is below this the minimum value is used instead
 * @param { number } maxValue - the maximum value the component can be if a value is chosen which is above this the maximum value is used instead
 * @param { number } increment - the increment amount on "step" is
 * @param { (number, string) => void } emitValueChange - a function to handle when either the value or unit selection has changed
 * @param { boolean } fullWidth - whether the component is full width or not
 * NOTE: if there is only one element in the units array the unit selector will not be shown
 */
export const IncrementalValueComponent: FunctionComponent<IncrementalValueProps> = ({
    value,
    selectedUnitId,
    units,
    minValue,
    maxValue,
    increment,
    changeValue: emitValueChange,
    showUnit = units?.length > 0,
    className,
    disabled,
    decimals = 0,
    fullWidth = false,
    testId,
    dataCy,
    hideError = false,
    alwaysEmitValue = false,
    dataTestId = "incremental-value-component",
}) => {
    const translate = useIventisTranslate();

    // set the initial state of the component for the value and units selected
    // need to store state inside the component until we implement the different units of measurement within the map
    const [selectedUnit, setUnit] = useState(selectedUnitId);

    const [inputValue, setInputValue] = useState<string>(parseFloat(`${value}`).toFixed(decimals));

    const [invalid, setInvalid] = useState(false);
    const [focused, setFocused] = useState(false);

    // update the inputValue if value prop is changed
    useEffect(() => {
        if (!focused) {
            setInputValue(parseFloat(`${value}`).toFixed(decimals));
        }
    }, [value]);

    // handles an incremental change
    const handleStepValueChange = (change: StepChange) => {
        if (change === StepChange.INCREASE && value < maxValue) {
            const newValue = value + increment;
            valueValidation(newValue);
        } else if (change === StepChange.DECREASE && value > minValue) {
            const newValue = value - increment;
            valueValidation(newValue);
        }
    };

    // handles the units being changed
    const handleUnitChange = (newUnit: string) => {
        setUnit(newUnit);
        emitValueChange(value);
    };

    // validates any input of value with the min and max values
    const valueValidation = (newValue: number) => {
        if (alwaysEmitValue) {
            emitValueChange(newValue);
            setInputValue(decimalAdjust("round", newValue, decimals * -1));
            setInvalid(newValue > maxValue || newValue < minValue);
        } else if (newValue > maxValue) {
            // If a valid value within min/max, emit value change and update input value
            emitValueChange(maxValue);
            setInputValue(decimalAdjust("round", newValue, decimals * -1));
            setInvalid(true);
        } else if (newValue < minValue) {
            emitValueChange(minValue);
            setInputValue(decimalAdjust("round", newValue, decimals * -1));
            setInvalid(true);
        } else {
            // If not valid, do not emit, just update input value
            changeValue(decimalAdjust("round", newValue, decimals * -1));
            setInvalid(false);
        }
    };

    // value changed from user typing
    const valueInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        // If empty, emit min value
        if (event.target.value === "" || event.target.value === "0.0") {
            emitValueChange(minValue);
            setInputValue(event.target.value);
        } else {
            const parsedValue = decimalAdjust("round", parseFloat(event.target.value), decimals * -1);
            valueValidation(parsedValue);
        }
    };

    const changeValue = (newValue: number) => {
        setInputValue(newValue.toString());
        emitValueChange(newValue);
    };

    return (
        <>
            <StyledIncrementalContainer className={className} data-testid={dataTestId}>
                <StyledValueStepper showUnit={showUnit} fullWidth={fullWidth}>
                    {/* Decrease value */}
                    <StyledIncrementElement className="change-value">
                        <button type="button" onClick={() => handleStepValueChange(StepChange.DECREASE)} disabled={disabled} data-testid="incremental-value-decrement">
                            <FontAwesomeIcon icon={["far", "minus"]} />
                        </button>
                    </StyledIncrementElement>

                    <StyledVerticalRule margin={5} ruleHeight={24} />

                    {/* SHOW VALUE, ALLOW USER TO TYPE VALUE */}
                    <StyledIncrementElement className="value" input>
                        {disabled && <DisabledOverlay />}
                        <StyledInput
                            type="number"
                            value={inputValue}
                            onFocus={() => setFocused(true)}
                            onBlur={() => {
                                setInputValue(parseFloat(`${inputValue}`).toFixed(decimals));
                                setFocused(false);
                            }}
                            onChange={valueInputChange}
                            disabled={disabled}
                            data-testid={testId == null ? "incremental-value-input" : `incremental-value-input-${testId}`}
                            data-cy={`incremental-value-input-${dataCy}`}
                        />
                    </StyledIncrementElement>

                    <StyledVerticalRule margin={5} ruleHeight={24} />

                    {/* Increase value */}
                    <StyledIncrementElement className="change-value">
                        <button
                            type="button"
                            onClick={() => handleStepValueChange(StepChange.INCREASE)}
                            disabled={disabled}
                            data-testid={dataTestIds.editLayer.incrementalValueInputIncrease(testId)}
                        >
                            <FontAwesomeIcon icon={["far", "plus"]} />
                        </button>
                    </StyledIncrementElement>
                </StyledValueStepper>
                {/* If there is only one unit */}
                {showUnit && (
                    <StyledUnit>
                        {units.length <= 1 && <span>{units.find((unit) => unit.id === selectedUnit).name}</span>}
                        {/* If there are mutiple units */}
                        {units.length > 1 && <SelectItemsComponent changeValue={handleUnitChange} options={units} value={selectedUnit} />}
                    </StyledUnit>
                )}
            </StyledIncrementalContainer>
            {!hideError && (
                <ErrorText data-testid="incremental-value-error">
                    {invalid && <span>{translate(Content.map4.style_errors.invalid_incrementor_value, { minValue, maxValue })}</span>}
                </ErrorText>
            )}
        </>
    );
};

export const ErrorText = styled.p`
    font-size: ${fontSizes.xSmall};
    color: ${(props) => props.theme.secondaryColors.error};
    padding-top: 1px;
    min-height: ${gapWithRoomForErrorMessage};
    position: absolute;
`;

export const StyledIncrementalContainer = styled.div<{ theme?: Theme }>`
    display: flex;
    align-items: center;
`;

export const incrementalInputWidth = "150px";

const StyledValueStepper = styled.div<{ showUnit: boolean; fullWidth: boolean }>`
    width: ${(props) => (props.fullWidth ? "100%" : incrementalInputWidth)};
    box-sizing: border-box;
    height: ${inputHeight};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${borderRadius.standard};
    background-color: ${(props) => props.theme.secondaryColors.blank};
    border: ${(props) => `1px solid ${props.theme.shades.two}}`};

    ${media.extraSmall} {
        width: 50%;
    }
`;

const StyledIncrementElement = styled.div<{ input?: boolean }>`
    height: 100%;
    min-height: 100%;
    width: ${(props) => (props.input ? `30%` : `25%`)};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .value {
        font-size: ${fontSizes.small};
    }
    button {
        border: none;
        background: transparent;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
    input:focus {
        outline: none;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

// TODO: Change label to drop down box for different units
const StyledUnit = styled.div`
    margin-left: 15px;
    display: flex;
    align-items: center;
    height: 100%;
`;

const StyledInput = styled.input`
    width: 100%;
    background: transparent;
    border: none;
    text-align: right;
    font-size: ${fontSizes.small};
    text-align: center;
`;

export const incrementalValueSelectorComponentCreator: ValueSelectorComponentCreator<IncrementalValueProps> = (additionalProps) => (genericProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <IncrementalValueComponent {...genericProps} {...additionalProps} />
);
