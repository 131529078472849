import { AreaDimension } from "@iventis/domain-model/model/areaDimension";
import { LineEnd } from "@iventis/domain-model/model/lineEnd";
import { LineJoin } from "@iventis/domain-model/model/lineJoin";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { IconPlacement } from "@iventis/domain-model/model/iconPlacement";
import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { SimulationDirection } from "@iventis/domain-model/model/simulationDirection";
import { SimulationDistribution } from "@iventis/domain-model/model/simulationDistribution";
import { PointStyle } from "@iventis/domain-model/model/pointStyle";
import { PointPitchAlignment } from "@iventis/domain-model/model/pointPitchAlignment";
import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { ModelStyle } from "@iventis/domain-model/model/modelStyle";
import { ZoomValue } from "@iventis/domain-model/model/zoomValue";
import { TextStyle } from "@iventis/domain-model/model/textStyle";
import { OutlineStyle } from "@iventis/domain-model/model/outlineStyle";
import { LineType } from "@iventis/domain-model/model/lineType";
import { IconOrientation } from "@iventis/domain-model/model/iconOrientation";
import { LineModelStyle } from "@iventis/domain-model/model/lineModelStyle";
import { EMPTY_GUID } from "@iventis/utilities";
import { TextPosition } from "@iventis/domain-model/model/textPosition";
import { IconAlignment } from "@iventis/domain-model/model/iconAlignment";
import { createStaticStyleValue } from "./static-styles";
import { PredefinedZoomLevels } from "../bridge/constants";
import { createDefaultStyleZoomableValue } from "./zoomable-values";

export const DEFAULT_LAYER_COLOUR_BLUE = "#4F7FDE";
export const DEFAULT_LAYER_COLOUR_GREY = "#53565A";

export const textDefaultValues: Omit<TextStyle, "styleType"> = {
    text: createStaticStyleValue(false),
    textContent: createStaticStyleValue(EMPTY_GUID),
    textColour: createStaticStyleValue("#000000"),
    textSize: createDefaultStyleZoomableValue({ city: 8, street: 12, max: 16 }),
    textOverlap: createStaticStyleValue(false),
    textOutlineColour: createStaticStyleValue("#FFFFFF"),
    textOutlineWidth: createStaticStyleValue(1),
    textBold: createStaticStyleValue(true),
    textItalic: createStaticStyleValue(false),
    textUnderlined: createStaticStyleValue(false),
    textOpacity: createStaticStyleValue(1),
    textPosition: createStaticStyleValue(TextPosition.Automatic),
    textOffset: createStaticStyleValue(0),
    objectOrder: createStaticStyleValue(0),
};

export const outlineDefaultValues: Omit<OutlineStyle, "styleType"> = {
    outline: createStaticStyleValue(false),
    outlineColour: createStaticStyleValue("#FFFFFF"),
    outlineOpacity: createStaticStyleValue(1),
    outlineWidth: createStaticStyleValue(1),
    outlineBlur: createStaticStyleValue(0),
    objectOrder: createStaticStyleValue(0),
};

// add these as actual type but have null for properties being used
export const defaultLineStyle: LineStyle = {
    styleType: StyleType.Line,
    type: createStaticStyleValue(LineType.Solid),
    colour: createStaticStyleValue(DEFAULT_LAYER_COLOUR_GREY),
    width: createStaticStyleValue(5),
    opacity: createStaticStyleValue(1),
    offset: createStaticStyleValue(0),
    end: createStaticStyleValue(LineEnd.Round),
    join: createStaticStyleValue(LineJoin.Round),
    blur: createStaticStyleValue(0),
    dash: createStaticStyleValue(0),
    arrows: createStaticStyleValue(false),
    arrowColourMatchesLine: createStaticStyleValue(true),
    arrowColour: createStaticStyleValue(DEFAULT_LAYER_COLOUR_GREY),
    arrowOpacity: createStaticStyleValue(1),
    arrowSize: createStaticStyleValue(0.8),
    arrowSpacing: createStaticStyleValue(50),
    isModel: createStaticStyleValue(false),
    model: null,
    iconPlacement: createStaticStyleValue(IconPlacement.Line),
    ...outlineDefaultValues,
    ...textDefaultValues,
} as const;

export const defaultAreaStyle: AreaStyle = {
    styleType: StyleType.Area,
    dimension: createStaticStyleValue(AreaDimension.Two),
    colour: createStaticStyleValue(DEFAULT_LAYER_COLOUR_BLUE),
    fill: createStaticStyleValue(true),
    opacity: createStaticStyleValue(0.7),
    simulation: createStaticStyleValue(false),
    simulationModel: null,
    simulationScale: createStaticStyleValue(1),
    simulationDirection: createStaticStyleValue(SimulationDirection.Uniformed),
    simulationDisturbution: createStaticStyleValue(SimulationDistribution.Random),
    grid: createStaticStyleValue(false),
    gridColour: createStaticStyleValue("red"),
    gridOrientation: createStaticStyleValue(0),
    gridWidth: createStaticStyleValue(10),
    gridLength: createStaticStyleValue(10),
    height: createStaticStyleValue(3),
    ...outlineDefaultValues,
    ...textDefaultValues,
} as const;

export const defaultPointStyle: PointStyle = {
    styleType: StyleType.Point,
    colour: createStaticStyleValue(DEFAULT_LAYER_COLOUR_BLUE),
    opacity: createStaticStyleValue(1),
    blur: createStaticStyleValue(0),
    radius: createDefaultStyleZoomableValue({ city: 3, street: 5, max: 10 }),
    outline: createStaticStyleValue(false),
    outlineColour: createStaticStyleValue("#000000"),
    outlineWidth: createStaticStyleValue(1),
    outlineOpacity: createStaticStyleValue(1),
    pitchAlignment: createStaticStyleValue(PointPitchAlignment.Map),
    ...textDefaultValues,
} as const;

export const defaultIconStyle: IconStyle = {
    styleType: StyleType.Icon,
    colour: createStaticStyleValue("#cccccc"),
    opacity: createStaticStyleValue(1),
    iconImage: null,
    size: createDefaultStyleZoomableValue({ city: 0, street: 0.5, max: 1 }),
    rotation: createStaticStyleValue(0),
    orientation: createStaticStyleValue(IconOrientation.Raise),
    allowOverlap: createStaticStyleValue(true),
    customColour: createStaticStyleValue(false),
    iconTextFit: createStaticStyleValue(false),
    iconTextFitMargin: createStaticStyleValue(10),
    iconAlignment: createStaticStyleValue(IconAlignment.Screen),
    ...textDefaultValues,
} as const;

export const defaultModelStyle: ModelStyle = {
    styleType: StyleType.Model,
    model: null,
    scale: createStaticStyleValue(1),
    height: createStaticStyleValue(1),
    width: createStaticStyleValue(1),
    length: createStaticStyleValue(1),
    objectOrder: createStaticStyleValue(0),
    customImage: createStaticStyleValue(EMPTY_GUID),
} as const;

export const defaultLineModelStyle: LineModelStyle = {
    styleType: StyleType.LineModel,
    model: null,
    scale: createStaticStyleValue(1),
    spacing: createStaticStyleValue(3),
    rotation: createStaticStyleValue(0),
    height: createStaticStyleValue(1),
    width: createStaticStyleValue(1),
    length: createStaticStyleValue(1),
    modelOffset: createStaticStyleValue(0),
    objectOrder: createStaticStyleValue(0),
    customImage: createStaticStyleValue(EMPTY_GUID),
} as const;

/**
 * Creates default values for zoom levels at 3, 6, 11, and 16 using the given staticValue
 * @param staticValue the static value of the zoomable level
 * @returns mapped zoom values
 */
export function defaultMappedZoomValues(staticValue: number | string | boolean): Record<number, ZoomValue<number | string | boolean>> {
    return {
        [PredefinedZoomLevels.max]: { value: staticValue ?? defaultValue(typeof staticValue), hidden: false },
        [PredefinedZoomLevels.street]: { value: staticValue ?? defaultValue(typeof staticValue), hidden: false },
        [PredefinedZoomLevels.city]: { value: staticValue ?? defaultValue(typeof staticValue), hidden: false },
        [PredefinedZoomLevels.country]: { value: defaultValue(typeof staticValue), hidden: true },
        [PredefinedZoomLevels.world]: { value: defaultValue(typeof staticValue), hidden: true },
    } as Record<number, ZoomValue<number | string | boolean>>;
}

export function defaultValue(valueType: string): number | string | boolean {
    switch (valueType) {
        case "number":
            return 0;
        case "string":
            return "";
        default:
            return false;
    }
}
